import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyStatisticsIndex } from '@/api/ceshi_xiugai/whole';
import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
export default {
  name: 'CollegeManagement',
  data: function data() {
    return {
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      title: '个人统计',
      stime: '',
      etime: '',
      value1: '',
      storageTime: [],
      NumberEs: 2,
      dataTiem: '',
      dataEnd: '',
      noData: false,
      easyDataOption: {
        style: 'width:20%',
        span: 4,
        data: [{
          title: '总数据量',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-zongshu1'
        }, {
          title: '被共享',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-gongxiang1'
        }, {
          title: '创建数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-chuangjian'
        }, {
          title: '跟进数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-genjin'
        }, {
          title: '报名成交数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-shichangchengjiaoshuju'
        }, {
          title: '回归公海数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/de58e9576c4d4f63528e215ef15b8d9b.jpg',
          key: 'icon-huiguijianmo'
        }, {
          title: '分配数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-daifenpeifuwushang'
        }, {
          title: '领取数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-lingqu'
        }, {
          title: '外送公海池数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-waisongdanweiguanli'
        }, {
          title: '未回访',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-huifang'
        }]
      },
      chartData: {
        columns: ['日期', '未回访总量', '被共享', '创建的'],
        rows: []
      },
      tableData: []
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.stime, this.etime);
    } else {}
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs
  },
  methods: {
    getList: function getList(stime, etime) {
      var _this = this;
      MyStatisticsIndex({
        stime: stime,
        etime: etime
      }).then(function (respomse) {
        var sum = respomse.data.sum;
        _this.easyDataOption.data[0].count = sum.private_sea;
        _this.easyDataOption.data[1].count = sum.be_shared_num;
        _this.easyDataOption.data[2].count = sum.create_num;
        _this.easyDataOption.data[3].count = sum.follow_num;
        _this.easyDataOption.data[4].count = sum.success_num;
        _this.easyDataOption.data[5].count = sum.back_num;
        _this.easyDataOption.data[6].count = sum.allot_num;
        _this.easyDataOption.data[7].count = sum.pull_num;
        _this.easyDataOption.data[8].count = sum.send_out_num;
        _this.easyDataOption.data[9].count = sum.untreated_num;
        _this.$children[0].$children[0].$forceUpdate();
        _this.chartData.rows = respomse.data.list.map(function (item, index) {
          return {
            日期: item.data_time,
            被共享: item.be_shared_num,
            创建的: item.create_num,
            未回访总量: item.untreated_num_sum
          };
        });
        if (respomse.data != '') {
          _this.noData = true;
        }
        _this.tableData = respomse.data.list;
      });
    },
    DateTime: function DateTime(item) {
      this.NumberEs = Number(0);
      //时间选择
      if (item !== null) {
        this.publicScreening(item[0], item[1]);
      }
    },
    publicScreening: function publicScreening(item1, item2) {
      var _this2 = this;
      //公共方法
      this.stime = item1.toString().substring(0, 10);
      this.etime = item2.toString().substring(0, 10);
      this.$nextTick(function () {
        _this2.getList(_this2.stime, _this2.etime);
      });
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.value1 = '';
      this.getList(item[0], item[1]);
    }
  }
};